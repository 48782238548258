/* eslint-disable no-empty */
<template>
  <div class="home_page">
    <!-- 二级导航 -->
    <div class="home_slider">
      <div
        class="item tab"
        @click="toJump('/supervision')"
        style="border-radius: 0.16rem 0px 0px 0.16rem"
      >
        <span class="pic1-1"></span>
        <span class="pic1-2"></span>
        督导组
      </div>
      <div class="item tab" @click="toJump('/admin/testconter/pesContent')">
        <span class="pic2-1"></span>
        <span class="pic2-2"></span>
        测试报告
      </div>
      <div class="item tab" @click="toJump('/sport')">
        <span class="pic3-1"></span>
        <span class="pic3-2"></span>
        小小运动会
      </div>
      <div class="item tab" @click="toJump('/train')">
        <span class="pic4-1"></span>
        <span class="pic4-2"></span>
        师资培训
      </div>
      <div class="item tab" @click="toJump('/shop')">
        <span class="pic5-1"></span>
        <span class="pic5-2"></span>
        商城
      </div>
      <!-- <div
        class="item tab"
        style="border-radius: 0px 0.16rem 0.16rem 0px"
        @click="toJump('/instructions')"
      >
        <span class="pic6-1"></span>
        <span class="pic6-2"></span>
        操作指引
      </div> -->

      <div
        class="item tab"
        style="border-radius: 0px 0.16rem 0.16rem 0px"

      >
        <span class="znpic6-1"></span>
        <span class="znpic6-2"></span>
        <span class="down-agree" @click="downAgree">指南</span>
      </div>
    </div>

    <!-- tab -->
    <div class="boxxx" @click="toJump('/shenbao')" v-show="shenbao">
      <img class="shenbao" src="../../assets/img/home/shenbao.png" alt="" />
    </div>
    <div class="center_block">
      <div class="jump_list tab">
        <div class="jump_list_item" @click="toJump('/steup')">
          <img src="../../assets/img/home/js.png" alt="" />
          <div class="flag"></div>
          <div class="jump_list_item_info">
            <div class="title">项目介绍</div>
            <div class="desc">project introduction</div>
            <div class="bar"></div>
          </div>
        </div>
        <div
          class="jump_list_item tab"
          @click="toJump('/courseList', { courseId: 2, isPremium: 0 })"
        >
          <img src="../../assets/img/home/ty.png" alt="" />
          <div class="flag"></div>
          <div class="jump_list_item_info">
            <div class="title">课程体验</div>
            <div class="desc">course experience</div>
            <div class="bar"></div>
          </div>
        </div>
        <div class="jump_list_item tab" @click="toJump('/adviser')">
          <img src="../../assets/img/home/gw.png" alt="" />
          <div class="flag"></div>
          <div class="jump_list_item_info">
            <div class="title">服务顾问</div>
            <div class="desc">service consultant</div>
            <div class="bar"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- news -->
    <div class="center_block">
      <titleComponent title="新闻资讯" />
      <div class="news">
        <div
          class="list tab"
          v-for="(item, index) in list"
          :key="index"
          @click="toReport(item)"
        >
          <div class="list_left">
            <img :src="constant.URL + item.coverImage" alt="" />
          </div>
          <div class="list_right">
            <div class="title">{{ item.title }}</div>
            <div class="info_block">
              <span class="address">{{ item.contentMarkdownSource }} </span>
              <span class="time"
                >{{
                  common.dateFormat(
                    parseInt(item.publishTime) * 1000,
                    "yyyy/MM/dd"
                  )
                }}
                <span>{{
                  common.dateFormat(parseInt(item.publishTime) * 1000, "HH:mm")
                }}</span></span
              >
              <span class="flag" v-show="item.hotFlag">New</span>
            </div>
            <div class="desc">{{ item.description }}</div>
          </div>
        </div>
        <div class="more tab" @click="toJump('/news')">查看更多>></div>
      </div>
    </div>
    <!-- 合作伙伴 -->
    <div class="about_partner">
      <titleComponent title="合作伙伴" />
      <div class="about_partner_block">
        <div
          class="about_partner_item"
          v-for="item in partner_list"
          :key="item.title"
        >
          <img :src="item.icon" alt="" />
          <div class="icon_info">
            <div class="text">{{ item.title }}</div>
            <div class="bar"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹窗 -->
    <div class="annunciate" v-if="isAnnunciate">
      <div class="annunciate-bg">
        <!-- <img
          src="../../assets/img/home/annunciate.png"
          alt=""
          class="annunciate_tis"
        /> -->
        <div class="annunciate-tit">
          <!-- <p >【小程序上线通知】</p> -->
          <div class="annunciate-code" @click="bindCode">
            <img
              src="../../assets/img/home/annunciate_code.png"
              alt=""
              class="annunciate-code-tis"
            />
          </div>
        </div>
        <div class="annunciate-cont">
          <p class="annunciate-cont-tit">【测试报告版本更新说明】</p>
          <swiper :options="swiperOption" ref="mySwiper" >
            <swiper-slide
              v-for="(item, index) in productList"
              :key="index"
            >
              <div class="swiper-text">
                <div class="swiper-text-box">
                  <h2 class="swiper-text-title">{{ item.title }}</h2>
                  <p class="swiper-text-con" v-if="index==0" v-html="item.cont1"></p>
                  <p class="swiper-text-con" v-else>{{item.cont}}</p>
                  <img class="swiper-text-img" :src="item.img" />
                </div>
              </div>
            </swiper-slide>
            <!-- Optional controls ,显示小点-->
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
          <!-- <div class="annunciate-cont-bottom">
            <p class="annunciate-cont-bottom-des">具体功能详见官网校园中心-产品中心页面，快去体验吧～</p>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import titleComponent from "@/components/home/title.vue";
// 引入插件
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
// import SwiperBlock from '@/components/swiper.vue'
// import videoPlayer from '@/components/video.vue'
// import TabMore from '@/components/home/tabMore.vue'
export default {
  name: "homeIndex",
  components: {
    titleComponent,
    swiper,
    swiperSlide,
    // SwiperBlock,
    // videoPlayer,
    // TabMore,
  },
  mounted() {},
  props: ["data_obj"],
  data() {
    return {
      isAnnunciate: false,
      shenbao: true,
      imgList: [
        {
          type: "img",
          url: require("@/assets/img/header/logo.png"),
        },
      ],
      list: [],
      partner_list: [
        {
          icon: require("../../assets/img/home/partner/boxset_media.png"),
          title: "boxset medla ltd",
        },
        {
          icon: require("../../assets/img/home/partner/well.png"),
          title: "WE enjoy learning ltd",
        },
        {
          icon: require("../../assets/img/home/partner/little_kickrs.png"),
          title: "LITTLE KICKRS",
        },
        {
          icon: require("../../assets/img/home/partner/football.png"),
          title: "北京足协",
        },
        {
          icon: require("../../assets/img/home/partner/icon_logo_jjh.png"),
          title: "中国足球发展基金会",
        },
        {
          icon: require("../../assets/img/home/partner/sport_news.png"),
          title: "sports.news.cn",
        },
        {
          icon: require("../../assets/img/home/partner/news.png"),
          title: "新华网",
        },
        {
          icon: require("../../assets/img/home/partner/icon_guardianship.png"),
          title: "ICON GUARDIANSHIP",
        },
        {
          icon: require("../../assets/img/home/partner/youku.png"),
          title: "优酷YOUKU",
        },
        {
          icon: require("../../assets/img/home/partner/fuzzfox_ltd.png"),
          title: "fuzzfox ltd",
        },
      ],
      productList:[
        {
          title:'1.PDA测试录入',
          cont1:'记录幼儿测试数据，测试项目分别为 <span style="color:#4175DCFF;">体质测试</span>（7项指标）身高/体重/视力/臂展/胸围/肺活量/ 坐姿体前屈；<span style="color:#4175DCFF;">运动测试</span>（8项指标）跑/跳/平衡/力量/敏捷/协调/物体操控（手/脚）。',
          cont:'',
          img:require("@/assets/img/home/Pic_PT_Tc_UpD_a.png")
        },
        {
          title:'2.高级查询',
          cont:'园所可根据测试指标进行多维度数据查询，查询结果支持数据可视化报告并导出；直观了解幼 儿测试数据。',
          img:require("@/assets/img/home/Pic_PT_Tc_UpD_b.png")
        },
        {
          title:'3.数据对比',
          cont:'园所可根据五大维度（综合评分/完成率/优秀率/增长速度/增长幅度）查看大数据对比及排名， 提供精准的量化参考。',
          img:require("@/assets/img/home/Pic_PT_Tc_UpD_c.png")
        },
        {
          title:'4.PES测试报告',
          cont:'针对幼儿提供全面测评报告，每次提供体质/运动数据报告及15项测试指标报告，共17份数据 报告，每月测试后实时更新。',
          img:require("@/assets/img/home/Pic_PT_Tc_UpD_d.png")
        }
      ],
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true //允许分页点击跳转
        },
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      },
    };
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit("setIsHome", 0);
    next();
  },
  created() {
    this.$store.commit("setIsHome", 1);
    this.init();
  },
  methods: {
    init() {
      this.getNews();
      this.shenqing();
      if (this.$store.state.isModel) {
        this.isAnnunciate = false;
      } else {
        this.isAnnunciate = false;
      }
    },
    getNews() {
      this.api.home.findNews({ type: 0 }).then((res) => {
        if (res.flag) {
          this.list = res.data;
        }
      });
    },
    shenqing() {
      this.api.admin.findFootabllApplyEndTime().then((res) => {
        this.shenbao = res.flag;
      });
    },
    bindCode() {
      this.isAnnunciate = false;
      this.$store.state.isModel = true;
    },
    toReport(item) {
      this.$router.push({
        path: "/newsDetail",
        query: {
          id: item.id,
        },
      });
    },
    toJump(val) {
      if (val == "/shenbao") {
        this.$notice({
          message:
            "2021年全国足球特色幼儿园申报已截止。如有疑问，请拨打400-8319-799或咨询当地教育主管部门。",
        }).isShow();
        // let schoolid = JSON.parse(localStorage.getItem("userInfo"));
        // console.log("schoolid2222", schoolid);
        // if (schoolid.token == undefined) {
        //   this.$store.commit("setLoginState", "login");
        // } else {
        //   this.$router.push({
        //     path: "/admin/declare/zuteDeclare",
        //   });
        //   // if (
        //   //   this.$store.state.userInfo.teacherId == null ||
        //   //   this.$store.state.userInfo.teacherId == ""
        //   // ) {
        //   //   this.api.admin
        //   //     .findFootballApplayStatus(this.$store.state.userInfo.schoolId)
        //   //     .then((res) => {
        //   //       if (res.data.apply == 0) {
        //   //         this.$router.push({
        //   //           path: val,
        //   //         });
        //   //       } else if (res.data.apply == 1) {
        //   //         this.$router.push({
        //   //           path: val,
        //   //         });
        //   //       } else if (res.data.apply == 2) {
        //   //         this.$router.push({
        //   //           path: val,
        //   //         });
        //   //       } else if (res.data.apply == 4) {
        //   //         this.$notice({
        //   //           message: "不具备申请足特园的要求!",
        //   //         }).isShow();
        //   //       } else if (res.data.apply == 3) {
        //   //         this.$notice({
        //   //           type: "success",
        //   //           message: "您已经是足特园用户不需要重复申请.",
        //   //         }).isShow();
        //   //       } else {
        //   //         this.$router.push({
        //   //           path: val,
        //   //         });
        //   //       }
        //   //     });
        //   // } else {
        //   //   this.$notice({
        //   //     type: "success",
        //   //     message: "请用园长账号进行申报",
        //   //   }).isShow();
        //   //   return;
        //   // }
        // }
      } else if (val == "/supervision") {
        // this.$notice({
        //   message: "敬请期待",
        // }).isShow();
        var superId = JSON.parse(localStorage.getItem("userInfo"));
        console.log("superId督导", superId);
        if (
          this.$store.state.userInfo.schoolId &&
          this.$store.state.userInfo.superviseId === ""
        ) {
          this.$notice({
            message: "请用督导账号登录",
          }).isShow();
          this.$store.commit("setLoginState", "login");
          return;
        }
        //this.$store.state.userInfo.token
        if (this.$store.state.userInfo.token == undefined) {
          console.log("没有token");
          this.$store.commit("setLoginState", "login");
        } else {
          this.$router.push({
            path: val,
          });
        }
      } else {
        console.log("11111");
        this.$router.push({
          path: val,
        });
      }
    },
    downAgree(){    //下载知情同意书
        window.location.href = '/全国足球特色幼儿园游戏活动指南.pdf';
    }
  },
};
</script>

<style lang="scss" scoped>
.center_block {
  display: block;
  width: 1434px;
  margin: 0 auto;
}

.home_slider {
  @include flex();
  width: 100%;
  height: 125px;
  background: #e6e5e5;

  .item {
    width: 234px;
    height: 89px;
    box-sizing: border-box;
    background: #c2292b;
    font-size: 30px;
    font-family: Alibaba PuHuiTi;
    font-weight: bold;
    color: #ffffff;
    margin-right: 6px;
    @include flex();
    .pic1-2,
    .pic2-2,
    .pic3-2,
    .pic4-2,
    .pic5-2,
    .pic6-2,
    .znpic6-2 {
      display: none;
    }
    .pic1-1,
    .pic2-1,
    .pic3-1,
    .pic4-1,
    .pic5-1,
    .pic6-1,
    .znpic6-1 {
      display: block;
    }
  }

  .item:last-child {
    margin-right: 0;
  }

  .active {
    font-family: Alibaba PuHuiTi;
    font-size: 30px;
    font-weight: bold;
    background: #fff;
    color: #cc1a30;
    box-sizing: border-box;
    border-bottom: solid 7px #cc1a30;
  }
}

.item:hover {
  font-size: 30px;
  font-family: Alibaba PuHuiTi;
  font-weight: bold;
  background: #fff;
  color: #cc1a30;
  border-bottom: solid 7px #cc1a30;
  .pic1-2,
  .pic2-2,
  .pic3-2,
  .pic4-2,
  .pic5-2,
  .pic6-2,
  .znpic6-2 {
    display: block;
  }
  .pic1-1,
  .pic2-1,
  .pic3-1,
  .pic4-1,
  .pic5-1,
  .pic6-1,
  .znpic6-1{
    display: none;
  }
}

.jump_list {
  width: 100%;
  margin: 30px 0 130px;
  @include flex();

  .jump_list_item {
    width: 456px;
    height: 235px;
    background: #cc1a30;
    border-radius: 15px;
    position: relative;
    margin-right: 33px;

    img {
      width: 100%;
      height: 100%;
    }

    .flag {
      width: 34px;
      height: 47px;
      @include bg("../../assets/img/home/flag.png");
      position: absolute;
      top: 0;
      left: 26px;
    }

    .jump_list_item_info {
      position: absolute;
      box-sizing: border-box;
      top: 69px;
      left: 28px;
      padding-bottom: 40px;

      .title {
        font-size: 36px;
        font-family: Alibaba PuHuiTi;
        font-weight: bold;
        color: #fdfbfb;
      }

      .desc {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #fdfbfb;
        margin: 13px 0 9px;
      }

      .bar {
        width: 66px;
        height: 7px;
        background: #fefeff;
      }
    }
  }

  .jump_list_item:last-child {
    margin-right: 0;
  }
}

.news {
  width: 100%;
  height: 100%;
  position: relative;

  .list {
    width: 100%;
    height: 200px;
    padding: 50px 0;
    @include flex(row, flex-start, flex-start);
    border-top: 1px solid #a9a8a8;

    .list_left {
      width: 328px;
      height: 189px;
      background: #d1d0d0;
      box-sizing: border-box;
      padding: 2px;
      border-radius: 2px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .list_right {
      flex: 1;
      box-sizing: border-box;
      padding-left: 65px;
      text-align: left;

      .info_block {
        @include flex(row, flex-start, center);
        margin-bottom: 32.97px;
      }

      .title {
        font-size: 29px;
        font-family: Alibaba PuHuiTi;
        font-weight: bold;
        color: #333333;
        margin-top: 15.52px;
        margin-bottom: 14.55px;
      }

      .address {
        font-size: 23px;
        font-family: Alibaba PuHuiTi;
        font-weight: 400;
        color: #333333;
        padding-right: 20px;
      }

      .desc {
        font-size: 23px;
        font-family: Alibaba PuHuiTi;
        font-weight: 400;
        color: #333333;
        @include text-hid(2);
      }

      .time {
        font-size: 23px;
        font-family: Alibaba PuHuiTi;
        font-weight: 400;
        color: #3565b5;

        span {
          color: #333333;
          padding-left: 20px;
        }
      }

      .flag {
        display: inline-block;
        width: 56px;
        height: 24px;
        background: #cc1a30;
        border-radius: 10px;
        font-size: 17px;
        font-family: Alibaba PuHuiTi;
        font-weight: bold;
        color: #ffffff;
        text-align: center;
        margin-left: 22.3px;
      }
    }
  }

  .list:first-child {
    border: none;
    padding-top: 0;
  }

  .more {
    position: absolute;
    top: -52px;
    right: 0;
    width: 184px;
    height: 42px;
    background: #3565b5;
    border-radius: 21px;
    line-height: 42px;
    text-align: center;
    font-size: 23px;
    font-family: Alibaba PuHuiTi;
    font-weight: bold;
    color: #ffffff;
  }
}

.about_partner {
  width: 100%;
  padding-bottom: 68px;
  background: #f3f0f0;
  box-sizing: border-box;
  padding-top: 50px;
}

.about_partner_block {
  width: 1376px;
  height: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  @include flex(row, flex-start, center);
  flex-wrap: wrap;

  .about_partner_item {
    width: 272px;
    height: 188px;
    background: #ffffff;
    box-sizing: border-box;
    margin-right: 3px;
    margin-bottom: 32px;
    padding: 28px 26px 0;
    position: relative;
    right: 1.5px;
    @include flex(column, flex-start, center);

    img {
      width: 220px;
      height: 78px;
    }

    .icon_info {
      @include flex(column, center, center);
    }

    .text {
      font-size: 16px;
      font-family: Alibaba PuHuiTi;
      font-weight: 400;
      color: #444444;
      margin: 24px 0 13px;
      white-space: nowrap;
    }

    .bar {
      width: 37px;
      height: 5px;
      background: #cc1a30;
    }
  }
}
.shenbao {
  width: 1434px;
  margin: 30px auto 0;
  display: block;
}
.boxxx {
  width: 100%;
}
.pic1-1 {
  @include bg("../../assets/img/home/pic1-1.png");
  height: 36px;
  width: 44px;
  margin-right: 10px;
}
.pic1-2 {
  @include bg("../../assets/img/home/pic1-2.jpg");
  height: 36px;
  width: 44px;
  margin-right: 10px;
}
.pic2-1 {
  @include bg("../../assets/img/home/pic2-1.png");
  height: 39px;
  width: 37px;
  margin-right: 10px;
}
.pic2-2 {
  @include bg("../../assets/img/home/pic2-2.jpg");
  height: 39px;
  width: 37px;
  margin-right: 10px;
}
.pic3-1 {
  @include bg("../../assets/img/home/pic3-1.png");
  height: 37px;
  width: 37px;
  margin-right: 10px;
}
.pic3-2 {
  @include bg("../../assets/img/home/pic3-2.jpg");
  height: 37px;
  width: 37px;
  margin-right: 10px;
}
.pic4-1 {
  @include bg("../../assets/img/home/pic4-1.png");
  height: 38px;
  width: 43px;
  margin-right: 10px;
}
.pic4-2 {
  @include bg("../../assets/img/home/pic4-2.jpg");
  height: 38px;
  width: 43px;
  margin-right: 10px;
}
.pic5-1 {
  @include bg("../../assets/img/home/pic5-1.png");
  height: 34px;
  width: 35px;
  margin-right: 10px;
}
.pic5-2 {
  @include bg("../../assets/img/home/pic5-2.jpg");
  height: 34px;
  width: 35px;
  margin-right: 10px;
}
.pic6-1 {
  @include bg("../../assets/img/home/pic6-1.png");
  height: 37px;
  width: 37px;
  margin-right: 10px;
}
.pic6-2 {
  @include bg("../../assets/img/home/pic6-2.jpg");
  height: 37px;
  width: 37px;
  margin-right: 10px;
}
.znpic6-1 {
  @include bg("../../assets/img/home/Icon_Zn_W.png");
  height: 37px;
  width: 37px;
  margin-right: 10px;
}
.znpic6-2 {
  @include bg("../../assets/img/home/Icon_Zn_R.png");
  height: 37px;
  width: 37px;
  margin-right: 10px;
}
.annunciate {
  width: 100%;
  height: 100%;
  position: fixed;
  background: #00000040;
  z-index: 999;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.annunciate-bg {
  width: 724px;
  // height: 703px;
  position: relative;
  // top: 50%;
  // left: 50%;
  // margin-top: -362px;
  // margin-left: -351.5px;
  z-index: 999;

  border-radius: 7px;
  // .annunciate_tis {
  //   width: 724px;
  //   height: 703px;
  // }
  .annunciate-tit {
    text-align: center;
    height: 198px;
    line-height: 198px;
    background: url("../../assets/img/home/Pic_Bg_Tc_Top.png");
    background-size: 100% 100%;
    border-radius: 7px 7px 0 0;
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
    position: relative;
  }
  .annunciate-cont {
    padding: 0 26px 30px 36px;
    background: #ffffff;
    position: relative;

    .annunciate-cont-tit {
      margin-top: -10px;
      margin-bottom: 22px;
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      color: #333333;
    }
    .annunciate-cont-des {
      margin-bottom: 5px;
      font-size: 16px;
      color: #333333;
      @include flex(row, space-between, center);
      .annunciate-cont-des-left {
        margin-right: 40px;
        > p:nth-child(1) {
          margin-bottom: 16px;
          font-size: 18px;
          font-weight: bold;
          color: #333333;
        }
        > p:nth-child(2) {
          line-height: 26px;
        }
      }
    }
    .annunciate-cont-bottom {
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -232px;
      margin-top: -10px;
      width: 465px;
      text-align: center;
      background: url('../../assets/img/home/Pic_Bg_TS.png');
      background-size: 465px 46px;
      height: 46px;
      font-size: 16px;
      font-weight: 400;
      color: #ffffff;

      >p{
        margin-top: 20px;
      }
    }
    // .annunciate-cont-l{
    //   margin-right:24Px;
    //   width:260Px;
    //   font-size: 16Px;
    //   color: #333333;
    //   .annunciate-cont-l-p{
    //     margin-bottom:12Px;
    //     line-height: 28Px;
    //   }
    //   .annunciate-cont-l-p-b{
    //     font-weight: bold;
    //     margin-bottom:14Px;
    //   }
    //   .annunciate-cont-l-p-sub{
    //     font-weight: bold;
    //     margin-bottom:12Px;
    //   }
    //   .annunciate-cont-l-bot{
    //     margin-top: -30Px;
    //     @include flex(row,center,center);
    //     height:118Px;
    //     >img{

    //       width:118Px;
    //       height:118Px;
    //     }
    //     .annunciate-cont-l-bot-r{
    //       margin-left:10Px;
    //       >p:nth-child(1){
    //         margin-bottom:10Px;
    //       }
    //     }
    //   }
    // }
    // .annunciate-cont-r{
    //   width:380Px;
    //   >img{
    //     width:380Px;
    //   }
    // }
  }
  .swiper-text{
    width: 100%;
    overflow: hidden;
  }
  .swiper-text-title{
    margin-bottom: 16px;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
  }
  .swiper-text-con{
    //margin-bottom: 50px;
    font-size: 16px;
    color: #333333;
    line-height: 26px;
  }
  .swiper-text-img{
    width: 100%;
  }
  .annunciate-code {
    position: absolute;
    right: 20px;
    top: 0;
    cursor: pointer;
    .annunciate-code-tis {
      width: 15px;
      height: 15px;
    }
  }
}

</style>
<style lang="scss">
.annunciate{
  .swiper-pagination-bullet{
    width: 24px;
    height: 4px;
    background: #DDDDDD;
    border-radius: 2px;
    opacity: 1;
  }
  .swiper-pagination-bullet-active{
    background: rgba(120, 166, 255, 1);
  }
  .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets{
  bottom:0 !important;}
}

</style>
